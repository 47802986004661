import * as React from "react";

import Layout from "../components/layout";
import LinkButton from "../components/linkbutton";
import driveYouCrazy from "../images/drive-you-crazy.jpg";
// import happyHolidayImage from "../images/happy-holidays.jpg";
import "./all.scss";

const IndexPage = () => (
  <Layout>
    <IndexPageHeader />
    <br />
    <IndexPageContent />
  </Layout>
);

const IndexPageHeader = () => (
  <div className="container">
    <div className="columns">
      <div className="column has-text-centered">
        <h2 className="title is-2">Julie Sidley CPA, EA, LLC</h2>
        <hr />
      </div>
    </div>
  </div>
);

const IndexPageContent = () => (
  <div className="container">
    <div className="columns is-centered">
      <IndexPageButtons />
      <IndexPageCard />
    </div>
  </div>
);

const IndexPageButtons = () => (
  <div className="column is-narrow has-text-centered" style={{ margin: "auto 0" }}>
    <p className="content" style={{ width: 200, margin: "auto" }}>
      <LinkButton href="https://juliesidleycpa.securefilepro.com/portal/#/login" target="_blank" text="Client Portal" />
      <br />
      <LinkButton href="portal-help" text="Portal Help" />
      <br />
      <LinkButton
        href="https://secure.cpacharge.com/pages/juliesidleycpa/payments"
        target="_blank"
        text="Pay Your Bill"
      />
      <br />
      <LinkButton href="https://www.my1040pro.com/juliesidleycpa" target="_blank" text="Tax info and fact sheets" />
    </p>
  </div>
);

const IndexPageCard = () => (
  <div className="column is-narrow">
    <div className="card" style={{ width: 300, backgroundColor: "#f9f9f9", margin: "auto" }}>
      <div className="card-image has-text-centered is-paddingless">
        <figure className="image is-inline-block">
          <img
            className="is-rounded"
            src={driveYouCrazy}
            style={{ width: 200, borderTopLeftRadius: 9999, borderTopRightRadius: 9999 }}
          />
        </figure>
      </div>
      <div className="card-content" style={{ fontSize: 16, fontWeight: "bold", padding: "1rem", textAlign: "center" }}>
        {"Don't let your taxes drive you crazy"}
      </div>
    </div>
  </div>
);

export default IndexPage;
