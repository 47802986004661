import React from "react";

const LinkButton = ({ href, target, text }) => (
  <div className="field is-vertical">
    <p className="control">
      <a className="button is-link" href={href} target={target} rel="noreferrer">
        {text}
      </a>
    </p>
  </div>
);

export default LinkButton;
